import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Faq from '../components/Faq/index'
import PageTitle from '../components/Blocks/PageTitle'
import ReviewsSlider from '../components/ReviewsSlider'

const PageTemplate = ({ title, content, faq }) => (
  <div className="w-full">
    <div
      className={'transition-all page-content'}
      dangerouslySetInnerHTML={{ __html: content }}
    />
    <ReviewsSlider />
    <div className="py-20 bg-brand-blue-darker">
      {faq?.title && <Faq color="white" title={faq?.title} items={faq?.faqs} />}
    </div>
  </div>
)

const WithReviews = ({ data, pageContext }) => {
  const { wpPage: page } = data
  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} />

      <PageTemplate title={page.title} content={page.content} faq={page.faq} />
    </Layout>
  )
}

export default WithReviews

export const pageQuery = graphql`
  query WithReviews($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      faq {
        title
        faqs {
          body
          title
        }
      }
    }
  }
`
